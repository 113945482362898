import React from 'react';
import styled from 'styled-components';
import { Icon, IconLogo } from '@components/icons';
import { socialMedia } from '@config';
import useRandomTagline from '../hooks/useRandomTagline';

const FooterContainer = styled.footer`
  max-width: 1500px;
  margin: 0 auto;
  font-size: 0.875rem;
  position: relative;
  z-index: 10;
  color: var(--light-slate);
  font-family: var(--font-mono);
  font-size: var(--fz-xs);
`;

const InnerWrapper = styled.div`
  border-top: 1px solid var(--dark-slate);
  padding: 3rem 1rem;
  margin: 0 auto;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin: 0 auto;
  margin-bottom: 2.5rem;
  text-align: center;

  .logo {
    ${({ theme }) => theme.mixins.flexCenter};

    a {
      color: var(--orange);
      width: 20px;
      height: 20px;

      &:hover,
      &:focus {
        svg {
          fill: var(--orange-tint);
        }
      }

      svg {
        fill: none;
        transition: var(--transition);
        user-select: none;
      }
    }
  }

  @media (min-width: 768px) {
    margin: 0;
    text-align: left;
  }
`;

const Tagline = styled.p`
  max-width: 20rem;
  display: flex;
  color: var(--slate);
`;

const LinksGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2.5rem;
  text-align: center;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 5rem;
    text-align: left;
  }
`;

const LinkSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const SectionTitle = styled.h3`
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: uppercase;
  margin: 0;
`;

const LinkList = styled.ul`
  display: flex;
  color: var(--slate);
  list-style: none;
  flex-direction: column;
  padding: 0;
  margin: 0;
  gap: 0.75rem;
`;

const BottomBar = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 1rem;
  border-top: 1px solid var(--dark-slate);
  color: var(--slate);
  padding: 2rem 1rem;
  text-align: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
    text-align: left;
  }
`;

const CopyRight = styled.div`
  width: fit-content;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem; /* Equivalent to space-x-2 */

  @media (min-width: 768px) {
    justify-content: flex-start;
    flex-direction: row;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
`;

const SocialIconLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover svg {
    fill: ${({ color }) => color};
  }
`;

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const tagline = useRandomTagline();

  return (
    <FooterContainer>
      <InnerWrapper>
        <FlexContainer>
          <LogoSection>
            <LogoWrapper>
              <div className="logo" tabIndex="-1">
                <a href="/" aria-label="home">
                  <IconLogo />
                </a>
              </div>
              <div>Emile Choghi</div>
            </LogoWrapper>

            <Tagline>{tagline}</Tagline>
          </LogoSection>
          <LinksGrid>
            <LinkSection>
              <SectionTitle>Site</SectionTitle>
              <LinkList>
                <li>
                  <a href="/#about">About</a>
                </li>
                <li>
                  <a href="/#jobs">Experience</a>
                </li>
                <li>
                  <a href="/#projects">Work</a>
                </li>
              </LinkList>
            </LinkSection>
            <LinkSection>
              <SectionTitle>Work</SectionTitle>
              <LinkList>
                <li>
                  <a href="https://rennalabs.xyz" target="_blank" rel="noreferrer">
                    Renna Labs
                  </a>
                </li>
                <li>
                  <a href="https://gig.fish" target="_blank" rel="noreferrer">
                    GigFish
                  </a>
                </li>
                <li>
                  <a href="https://misogi.so" target="_blank" rel="noreferrer">
                    Misogi
                  </a>
                </li>
              </LinkList>
            </LinkSection>
          </LinksGrid>
        </FlexContainer>
      </InnerWrapper>
      <BottomBar>
        <CopyRight>&copy; {currentYear} Emile Choghi. All rights reserved</CopyRight>

        <SocialLinks>
          {socialMedia &&
            socialMedia.map(({ name, url }, i) => (
              <SocialIconLink key={i} href={url} target="_blank" rel="noreferrer">
                <Icon name={name} />
              </SocialIconLink>
            ))}
        </SocialLinks>
      </BottomBar>
    </FooterContainer>
  );
}
