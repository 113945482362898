import { useState, useEffect } from 'react';

const useHitCounter = (slug = 'home') => {
  const [hits, setHits] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHits = async () => {
      if (process.env.NODE_ENV !== 'production') {
        setHits(999);
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`/.netlify/functions/register-hit?slug=${slug}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();

        if (typeof data.hits === 'number') {
          setHits(data.hits);
        } else {
          throw new Error('Invalid response format');
        }
      } catch (err) {
        console.error('Failed to fetch hit count:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchHits();
  }, [slug]);

  return { hits, loading, error };
};

export default useHitCounter;
