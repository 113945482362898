import { useState } from 'react';

const taglines = [
  'Build your own dreams, or someone else will hire you to build theirs. What will you get done this week?',
  'If you’re going to try, go all the way. Its the only good fight there is.',
  `Just like moons and like suns, with the certainty of tides, just like hopes springing high, still I'll rise.`,
  `Prefiero morir de pie que vivir siempre arrodillado!`,
  `Don’t take yourself so seriously. You’re just a monkey with a plan.`,
  `The three most harmful addictions are heroin, carbohydrates, and a monthly salary.`,
  `You are not going to get rich renting out your time. You must own equity to gain your financial freedom.`,
  `Any meeting with eight people sitting around at a conference table, nothing is getting done. You are literally just dying one hour at a time.`,
];

const useRandomTagline = () => {
  const [randomString] = useState(() => {
    const randomIndex = Math.floor(Math.random() * taglines.length);
    return taglines[randomIndex];
  });

  return randomString;
};

export default useRandomTagline;
